import React, { useRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const ResultsTable = ({ data = [], searchPartNumber, secondarySearch, setSecondarySearch }) => {
  const searchRef = useRef(null);
  const secondarySearchRef = useRef(null);
  const [sortField, setSortField] = useState('ID_Top');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.scrollIntoView({ behavior: 'smooth', block:'center', inline:'nearest' });
    }
  }, [searchPartNumber, data, sortField, sortOrder]);

  useEffect(() => {
    if (secondarySearchRef.current) {
      secondarySearchRef.current.scrollIntoView({ behavior: 'smooth', block:'center', inline:'nearest' });
    }
  }, [secondarySearch, data, sortField, sortOrder]);

  useEffect(() => {
    setSortField('ID_Top');
    setSortOrder('asc');
  }, [searchPartNumber]);

  useEffect(() => {
    if (searchPartNumber && !data.some(row => row.Part_no === searchPartNumber)) {
      toast.error("No matching part number found.");
    }
  }, [data, searchPartNumber]);

  useEffect(() => {
    if (secondarySearch && (!data || data.length === 0)) {
      toast.error("No data available for secondary search.");
    }
  }, [data, secondarySearch]);

  const handleSort = (field) => {
    setSortOrder(sortField === field && sortOrder === 'asc' ? 'desc' : 'asc');
    setSortField(field);
  };

  try {
    // data sorting
    data.sort((a, b) => {
      const valA = a[sortField] !== null ? a[sortField] : (sortOrder === 'asc' ? -Infinity : Infinity);
      const valB = b[sortField] !== null ? b[sortField] : (sortOrder === 'asc' ? -Infinity : Infinity);
  
      if (typeof valA === 'number' && typeof valB === 'number') {
        return sortOrder === 'asc'
          ? valA - valB
          : valB - valA;
      } else {
        return sortOrder === 'asc'
          ? String(valA).localeCompare(String(valB))
          : String(valB).localeCompare(String(valA));
      }
    });
  } catch (error) {
    console.error("An error occurred during sorting:", error);
    toast.error("An error occurred during sorting.");
  }

  return (
    <div className='mx-9 mb-9 rounded-[10px] border-2 border-gray-200 overflow-y-auto' style={{ maxHeight: '650px' }}>
      <table className='w-full table-auto '>
        <thead>
          <tr className='bg-black h-14 sticky top-0'>
            <th className='px-2 text-white text-left font-bold cursor-pointer text-overflow-ellipsis'>Part #</th>
            <th className='px-2 text-white text-left font-bold cursor-pointer text-overflow-ellipsis' onClick={() => handleSort('ID_Top')}>ID Top {sortField === 'ID_Top' ? (sortOrder === 'asc' ? '↑' : '↓') : '›'}</th>
            <th className='px-2 text-white text-left font-bold cursor-pointer text-overflow-ellipsis' onClick={() => handleSort('ID_Mid')}>ID Mid {sortField === 'ID_Mid' ? (sortOrder === 'asc' ? '↑' : '↓') : '›'}</th>
            <th className='px-2 text-white text-left font-bold cursor-pointer text-overflow-ellipsis' onClick={() => handleSort('ID_Bott')}>ID Bott {sortField === 'ID_Bott' ? (sortOrder === 'asc' ? '↑' : '↓') : '›'}</th>
            <th className='px-2 text-white text-left font-bold cursor-pointer text-overflow-ellipsis' onClick={() => handleSort('WD')}>WD {sortField === 'WD' ? (sortOrder === 'asc' ? '↑' : '↓') : '›'}</th>
            <th className='px-2 text-white text-left font-bold cursor-pointer text-overflow-ellipsis' onClick={() => handleSort('No_Coils')}>No. Coils {sortField === 'No_Coils' ? (sortOrder === 'asc' ? '↑' : '↓') : '›'}</th>
            <th className='px-2 text-white text-left font-bold cursor-pointer text-overflow-ellipsis' onClick={() => handleSort('Free_Height')}>Free Hgt {sortField === 'Free_Height' ? (sortOrder === 'asc' ? '↑' : '↓') : '›'}</th>
            <th className='px-2 text-white text-left font-bold cursor-pointer text-overflow-ellipsis' >Trap Hgt</th>
            <th className='px-2 text-white text-left font-bold cursor-pointer text-overflow-ellipsis' onClick={() => handleSort('Top')}>Top {sortField === 'Top' ? (sortOrder === 'asc' ? '↑' : '↓') : '›'}</th>
            <th className='px-2 text-white text-left font-bold cursor-pointer text-overflow-ellipsis' onClick={() => handleSort('Bott')}>Bott {sortField === 'Bott' ? (sortOrder === 'asc' ? '↑' : '↓') : '›'}</th>
            <th className='px-2 text-white text-left font-bold cursor-pointer text-overflow-ellipsis' onClick={() => handleSort('Rate_lb_in')}>Rate lb/in {sortField === 'Rate_lb_in' ? (sortOrder === 'asc' ? '↑' : '↓') : '›'}</th>
            <th className='px-2 text-white text-left font-bold cursor-pointer text-overflow-ellipsis' onClick={() => handleSort('Rate_kg_mm')}>Rate kg/mm {sortField === 'Rate_kg_mm' ? (sortOrder === 'asc' ? '↑' : '↓') : '›'}</th>
            <th className='px-2 text-white text-left font-bold cursor-pointer text-overflow-ellipsis' >Design</th>
            <th className='px-2 text-white text-left font-bold cursor-pointer text-overflow-ellipsis' >Hgt Change @ Coil</th>
            <th className='px-2 text-white text-left font-bold cursor-pointer text-overflow-ellipsis' onClick={() => handleSort('RateIncrease')}>Rate Inc {sortField === 'RateIncrease' ? (sortOrder === 'asc' ? '↑' : '↓') : '›'}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
          if (row && 'HeightChange' in row) {
            const isSearched = row.Part_no === searchPartNumber;
            const isSecondarySearched = row.Part_no === secondarySearch;
            return (
              <tr
                key={index}
                ref={isSearched ? searchRef : isSecondarySearched ? secondarySearchRef : null}
                className={`h-14 ${isSearched || isSecondarySearched ? 'bg-[#ec3f3b] text-white font-bold' : 'hover:bg-gray-300'} ${index % 2 === 0 ? 'bg-[#ebebeb]' : ''}`}
              >
                  <td className='px-2 font-bold underline'>
                    <a href={`https://parts-iq.prontoavenue.biz/products/${row.Part_no}`} target="_blank" rel="noopener noreferrer">
                      {row.Part_no}
                    </a>
                  </td>
                  <td className='px-2 font-bold'>{row.ID_Top}</td>
                  <td className='px-2 font-bold'>{row.ID_Mid}</td>
                  <td className='px-2 font-bold'>{row.ID_Bott}</td>
                  <td className='px-2 font-bold'>{row.WD}</td>
                  <td className='px-2 font-bold'>{row.No_Coils}</td>
                  <td className='px-2 font-bold'>{row.Free_Height} mm</td>
                  <td className='px-2 font-bold'>{row.Trap_Height>0 ? `${row.Trap_Height} mm` : '-'}</td>
                  <td className='px-2 font-bold'>{row.Top}</td>
                  <td className='px-2 font-bold'>{row.Bott}</td>
                  <td className='px-2 font-bold'>{row.Rate_lb_in}</td>
                  <td className='px-2 font-bold'>{row.Rate_kg_mm}</td>
                  <td className='px-2 font-bold'>{row.Design}</td>
                  <td className='px-2 font-bold'>{row.HeightChange != null ? row.HeightChange.toFixed(0) : ''} mm</td>
                  <td className='px-2 font-bold'>{row.RateIncrease}</td>
                </tr>
              );
            } else {
              return null;
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ResultsTable;
