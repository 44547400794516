import React from 'react';

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  h1: {
    fontSize: '28px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#EC3F3B',
  },
  h2: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '15px',
  },
  h3: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '15px',
    marginBottom: '10px',
  },
  p: {
    marginBottom: '15px',
    fontSize: '16px',
    lineHeight: '1.5',
  },
  ul: {
    marginBottom: '20px',
  },
  li: {
    marginBottom: '10px',
    fontSize: '16px',
  },
  a: {
    color: '#EC3F3B',
    textDecoration: 'none',
  },
};

const UserGuide = () => (
  <div style={styles.container}>
    <h1 style={styles.h1}>Pedders Spring Comparator User Guide</h1>

    <h2 style={styles.h2}>Introduction:</h2>
    <p style={styles.p}>Welcome to the Pedders Spring Comparator User Guide! This tool is designed to assist Pedders Team Members in comparing various coil spring parts. With an intuitive interface, you can search for matching parts, see differences in products, and gain insights into attributes such as height change and percentage rate increase.</p>

    <h2 style={styles.h2}>Getting Started:</h2>
    <ol>
      <li style={styles.li}>Open your preferred web browser.</li>
      <li style={styles.li}>Navigate to the application's URL.</li>
      <li style={styles.li}>You will be greeted with the main interface, ready for your input.</li>
    </ol>

    <h2 style={styles.h2}>Main Features:</h2>
    <ul style={styles.ul}>
      <li style={styles.li}><strong>Search and Compare:</strong> Input a part number to retrieve detailed information about matching coil spring parts.</li>
      <li style={styles.li}><strong>Reverse Search:</strong> After an initial search, you can conduct a secondary search within the primary search results.</li>
      <li style={styles.li}><strong>Sorting Results:</strong> The results table offers a sorting functionality, allowing you to order displayed parts based on specific attributes.</li>
      <li style={styles.li}><strong>Height and Rate Calculations:</strong> The application calculates the height change and the percentage rate increase for matched parts, offering insights into differences between parts.</li>
      <li style={styles.li}><strong>Linking to PartsIQ:</strong> Easily find your desired spring, click the part number and you will be taken to the order page on PartsIQ!</li>
    </ul>

    <h2 style={styles.h2}>How to Use:</h2>
    <h3 style={styles.h3}>Part Number Search:</h3>
    <ol>
      <li style={styles.li}>Locate the search bar at the top of the page.</li>
      <li style={styles.li}>Enter the part number you wish to search for.</li>
      <li style={styles.li}>Click the "Search" button.</li>
      <li style={styles.li}>The matching results will be displayed in a table below the search bar.</li>
      <li style={styles.li}>View the Spring in PartsIQ simply by clicking on the Part Number in the left hand column.</li>
    </ol>
    
    <h3 style={styles.h3}>Reverse Search:</h3>
    <ol>
      <li style={styles.li}>After performing an initial search, click on the "Lookup" button.</li>
      <li style={styles.li}>A dialog will appear, allowing you to enter a secondary part number.</li>
      <li style={styles.li}>After entering the secondary part number, click "Search".</li>
      <li style={styles.li}>The table will update to show differences between the primary and secondary searched parts, including height change and percentage rate increase.</li>
    </ol>

    <h3 style={styles.h3}>Sorting Results:</h3>
    <ol>
      <li style={styles.li}>In the results table, you'll notice headers for each column, such as "Part #", "ID Top", "ID Mid", and more.</li>
      <li style={styles.li}>Click on any column header to sort the results based on that attribute.</li>
      <li style={styles.li}>Clicking the same header again will reverse the sort order.</li>
    </ol>

    <h2 style={styles.h2}>Troubleshooting:</h2>
    <ul style={styles.ul}>
      <li style={styles.li}><strong>No Results Displayed:</strong> If no results are displayed after a search, ensure the part number entered is correct. Try another known part number to verify.</li>
      <li style={styles.li}><strong>Unexpected Behaviour:</strong> If the application behaves unexpectedly or displays errors, try refreshing the page. If the issue persists, please contact support.</li>
    </ul>

    <h2 style={styles.h2}>Support and Feedback:</h2>
    <p style={styles.p}>For further assistance, questions, or to provide feedback on the Pedders Spring Comparator, please reach out to Josh – <a href="mailto:josh.yardy@pedders.com.au" style={styles.a}>josh.yardy@pedders.com.au</a></p>
    
    <hr />
    
    <p style={styles.p}>I hope this user guide assists you in effectively using the Pedders Spring Comparator. Happy searching and comparing!</p>
  </div>
);

export default UserGuide;
