import React, { useState, useEffect } from 'react';
import NavBar from './components/NavBar';
import ResultsTable from './components/ResultsTable';
import { ToastContainer } from 'react-toastify';
import './index.css';

function App() {
  const [data, setData] = useState([]);
  const [searchPartNumber, setSearchPartNumber] = useState('');
  const [secondarySearch, setSecondarySearch] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authCheckCompleted, setAuthCheckCompleted] = useState(false);

  useEffect(() => {
    async function checkAuthentication() {
      const response = await fetch('/check-auth', { credentials: 'include' });
      
      if (response.ok) {
        const data = await response.json();
        setIsAuthenticated(data.authenticated);
      } else {
        console.error("Failed to check authentication.");
      }

      setAuthCheckCompleted(true);
    }
    
    checkAuthentication();
  }, []);

  if (!isAuthenticated && authCheckCompleted) {
    window.location.href = '/login';
    return <div>Redirecting to login...</div>;
  }

  return (
    <div className='App'>
      <ToastContainer />
      <NavBar 
          setData={setData} 
          setSearchPartNumber={setSearchPartNumber} 
          setSecondarySearch={setSecondarySearch}
      />
      <ResultsTable 
          data={data} 
          searchPartNumber={searchPartNumber} 
          secondarySearch={secondarySearch} 
          setSecondarySearch={setSecondarySearch}
      />
    </div>
  );
}

export default App;
