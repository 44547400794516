import React, { useState } from "react";
import logo from "../assets/PeddersLogo.png";
import { SearchIcon, InformationCircleIcon as HelpIcon } from "@heroicons/react/outline"; 
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../index.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import UserGuide from "./UserGuide";

const NavBar = ({ setData, setSearchPartNumber, setSecondarySearch }) => {
  const [partNumber, setPartNumber] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);
  const [secondaryPartNumber, setSecondaryPartNumber] = useState("");
  const [firstSearchCompleted, setFirstSearchCompleted] = useState(false);
  const [userGuideOpen, setUserGuideOpen] = useState(false); // <-- Add this state to manage the UserGuide Dialog

  const handleSearch = (e) => {
    e.preventDefault();

    if (!partNumber.trim()) {
      toast.error("Please enter a valid part number to compare!");
      return;
    }

    const upperCasedPartNumber = partNumber.toUpperCase();

    fetch(`https://comparator.pedders.com.au/springs/${upperCasedPartNumber}`, { credentials: 'include' })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        return response.json();
      })
      .then((targetData) => {
        if (Object.keys(targetData).length === 0) {
          throw new Error(`Part number ${upperCasedPartNumber} not found`);
        }

        setSearchPartNumber(upperCasedPartNumber);
        setData(targetData);
        setFirstSearchCompleted(true);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      });

    setSecondarySearch("");
  };

  const handleSecondarySearch = () => {
    const upperCasedSecondaryPartNumber = secondaryPartNumber.toUpperCase();
    setSecondarySearch(upperCasedSecondaryPartNumber);
    setFilterOpen(false);
  };

  return (
    <>
      <nav className="flex items-center justify-between p-10 space-x-12">
        <div>
          <img src={logo} alt="Logo" className="h-20 w-auto" />
        </div>
        <form onSubmit={handleSearch} className="flex items-center">
          <div>
            <input
              type="text"
              className="px-4 py-2 rounded-full border-2 w-80"
              placeholder="Search Part No..."
              value={partNumber}
              onChange={(e) => setPartNumber(e.target.value)}
            />
          </div>
          <div>
            <button
              type="submit"
              className="px-2 py-2 mx-4 rounded-full bg-[#EC3F3B]"
            >
              <SearchIcon className="h-6 w-6 text-white" />
            </button>
          </div>
        </form>
        <div className="flex-grow"></div>
        <div className="flex items-center space-x-4"> 
          <button
            onClick={() => setUserGuideOpen(true)}
            className="px-4 py-2 font-bold text-white bg-[#EC3F3B] rounded-full border"
          >
            <HelpIcon className="h-6 w-6" />
          </button>
          {firstSearchCompleted && (
            <button
              onClick={() => setFilterOpen(true)}
              className="px-4 py-2 font-bold text-white bg-[#EC3F3B] rounded-full border"
            >
              Compare
            </button>
          )}
        </div>
      </nav>

      <Dialog
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        aria-labelledby="form-dialog-title"
        className="px-4 py-2 font-bold"
      >
        <DialogTitle id="form-dialog-title">Compare Specific Part #</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => {
            e.preventDefault();
            handleSecondarySearch();
          }}>
            <TextField
              autoFocus
              margin="dense"
              id="partNumber"
              label="Part #"
              type="text"
              fullWidth
              value={secondaryPartNumber}
              onChange={(e) => setSecondaryPartNumber(e.target.value)}
            />
            <DialogActions>
              <Button
                onClick={() => setFilterOpen(false)}
                className="font-bold text-black bg-gray-300 rounded-full border"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="font-bold text-white bg-[#EC3F3B] rounded-full border"
              >
                Search
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={userGuideOpen}
        onClose={() => setUserGuideOpen(false)}
        aria-labelledby="user-guide-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="user-guide-dialog-title">Pedders Spring Comparator User Guide</DialogTitle>
        <DialogContent>
          <UserGuide />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setUserGuideOpen(false)}
            className="font-bold text-black bg-gray-300 rounded-full border"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NavBar;
